import React from 'react';
import arrow from '../../../assets/images/header_arrow.png';
import './styles.css'

export const MooreSolution = ({ imgSrc, copy }) => (
  <div style={{
    padding: '50px 0',
    fontFamily: 'sans-serif'
  }}>
    <div style={{
      display: 'flex',
      flexFlow: 'row'
    }}>
      <div className="solution-header">
        <h3 className="about-h3">{copy[0]}</h3>
      </div>
      <div style={{margin: 0}}>
        <img className="arrow-img" src={arrow} />
      </div>
    </div>

    <div style={{
      display: 'flex',
      flexFlow: 'row',
      justifyContent: 'space-between',
      alignItems: 'space-between',
      fontFamily: 'FuturaBook'
    }}>
      <div className="solution-copy">
        <p className="copy-p">
          {copy[1]}
        </p>
      </div>

      <img src={imgSrc} className="solution-img" width="500" height="343" />
    </div>
  </div>
);