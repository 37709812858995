import React from 'react'
import './styles.css';

export const AboutHeader = ({ imgSrc, copy }) => (
  <div className="about-header" style={{ backgroundImage: `url(${imgSrc})` }}>
    <div>
      <h1 className="about-h1">
        {copy}
      </h1>
    </div>
  </div>
)
