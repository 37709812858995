import React from "react"
import Layout from "../layouts"
import { AboutHeader } from "../components/about-page/about-header"
import { MooreSolution } from "../components/about-page/moore-solution"

const AboutPage = ({ data }) => {
  const content = data.allContentfulAbout.edges[0].node

  return (
    <Layout>
      <AboutHeader
        imgSrc={content.headerImage.file.url}
        copy={content.headerCopy}
      />
      <MooreSolution
        imgSrc={content.solutionImage.file.url}
        copy={[content.solutionHeader, content.mooreSolution.mooreSolution]}
      />
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query aboutContent {
    allContentfulAbout {
      edges {
        node {
          headerCopy
          solutionHeader
          mooreSolution {
            mooreSolution
          }
          clients
          clients2
          clients3
          clients4

          headerImage {
            file {
              url
            }
          }
          solutionImage {
            file {
              url
            }
          }
          ourTeam {
            file {
              url
            }
          }
        }
      }
    }
  }
`
